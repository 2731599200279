<template>
	<div>
		<v-container fluid>
			<v-layout row wrap justify-center>

				<template v-if="!isLoadingSenders && !Senders.length">
					<v-fade-transition hide-on-leave>
						<v-container fill-height v-if="!isLoadingSenders && !Senders.length">
							<v-layout column justify-center>
								<v-flex xs12 sm8 md6 lg4 class="text-center">
									<h3 class="display-1 font-weight-light">Du har inte mottagit något lönebesked</h3>
									<v-btn color="accent" class="display-1 pb-3 font-weight-light" text @click="$router.push({name: 'Logout'})">
										Logga ut
									</v-btn>
								</v-flex>
							</v-layout>
						</v-container>
					</v-fade-transition>
				</template>

				<template v-if="!isLoadingSenders && Senders.length && (AvailableAnstNrSelections.length === 1 || SelectedAnstNr !== null)">
					<v-fade-transition hide-on-leave>
						<v-flex xs12 lg6 v-if="AvailableAnstNrSelections.length > 1">
							<v-select :value="SelectedAnstNr" @change="onChangeSelectedAnstNr" :loading="isLoadingSenders" light
												class="mt-2 mb-2" solo :items="AvailableAnstNrSelections" item-text="Namn" item-value="AnstNr" hide-details>
							</v-select>
						</v-flex>
					</v-fade-transition>

					<v-flex xs12></v-flex>

					<v-fade-transition hide-on-leave>
						<v-flex xs12 lg6 v-if="Saldon">
							<v-card class="text-center">
								<v-carousel height="200" dark active-class="active-carousel-item"
														hide-delimiters :cycle="true" :continuous="true">
									<v-carousel-item v-for="(group, index) in saldoGroups" :key="index">
										<v-container fill-height>
											<v-layout row wrap>

												<template v-for="(item, index2) in group">
													<v-flex xs12 sm6 :class="{'flex-divider': index2 !== group.length - 1}" :key="`groupItem_${index2}`">
														<v-img
																		class="text-center"
																		:src="require(`../assets/${item.image}`)"
																		:lazy-src="require(`../assets/${item.image}`)"
																		contain
																		:aspect-ratio="10/2"
														></v-img>
														<div class="headline grey--text font-weight-light"><AnimatedNumber :value="item.value" :format-options="item.formatOptions"/></div>
														<div class="subheading orange--text">{{item.label}}</div>
													</v-flex>
												</template>
											</v-layout>
										</v-container>
									</v-carousel-item>
								</v-carousel>
							</v-card>
						</v-flex>
					</v-fade-transition>

					<v-flex xs12></v-flex>

					<v-fade-transition hide-on-leave>
						<v-flex xs12 lg6 v-if="!isLoadingPayslips">
							<template v-for="(item, index) in Payslips">
								<v-list :dense="$vuetify.breakpoint.xsOnly" class="elevation-2 mt-2 pt-1 pb-1" :key="`list_${index}`">
									<v-list-item @click="openPDFNewTab(item.Id)">
										<v-list-item-avatar style="height: 60px !important;">
											<v-row dense>
												<v-col cols="12" class="mt-2">
													<v-avatar :size="30" color="accent">
														<span class="white--text">{{item.Period.substr(7)}}</span>
													</v-avatar>
													<div class="orange--text body-2" style="font-size: 12px !important; height: 20px;">{{getMonth(item.Period)}}</div>
												</v-col>
											</v-row>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>{{item.PerBen}}</v-list-item-title>
											<v-list-item-subtitle>{{$formatDecimal(item.BruttoLon, {unit: 'kr', allowZero: true})}}</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action>
											{{$formatDecimal(item.Utbetalt, {unit: 'kr', allowZero: true})}}
										</v-list-item-action>
									</v-list-item>

								</v-list>
							</template>
						</v-flex>
					</v-fade-transition>

					<v-flex xs12></v-flex>


				</template>
			</v-layout>
		</v-container>

		<v-fade-transition hide-on-leave>
			<div style="width: 100%; height: 70px;" v-if="Senders.length > 1 && !isLoadingSenders">
			<v-app-bar fixed dense :style="computedFooterStyle" flat>
				<v-layout row wrap justify-center>
					<v-flex xs12 lg6 v-if="Senders.length > 1">
						<v-select :value="SelectedSender" @change="onChangeSelectedSender" :loading="isLoadingSenders" light
														class="mt-2 mb-2" solo :items="Senders" item-text="Namn" item-value="Id" hide-details>
						</v-select>
					</v-flex>
				</v-layout>
			</v-app-bar>
		</div>
		</v-fade-transition>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex';

	import AnimatedNumber from './AnimatedNumber';

	import _ from 'lodash';

	export default {
		name: 'Payslips',

		watch: {
			'$route'() {
				this.loadSaldon({force: true});
				this.loadPayslips({force: true});
			},
		},

		async mounted() {
			try {
				const {SenderId} = this.$route.params;

				await this.loadSenders();

				if(!SenderId) {
					this.setSelectedSender(this.SelectedSender);
				}
			} catch(error) {}

		},

		methods: {
			...mapActions(['loadSenders', 'loadPayslips', 'loadSaldon', 'setSelectedSender', 'setSelectedAnstNr']),

			getMonth(period) {
				const [, , number] = period.split('.');
				const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAJ', 'JUN', 'JUL','AUG', 'SEP', 'OKT', 'NOV', 'DEC'];

				return months[parseInt(number, 10) - 1];
			},

			onChangeSelectedSender(value) {
				this.setSelectedSender(value);

				this.loadSaldon({force: true});
				this.loadPayslips({force: true});
			},

			onChangeSelectedAnstNr(value) {
				this.setSelectedAnstNr(value);

				this.loadSaldon({force: true});
				this.loadPayslips({force: true});
			},

			openPDFNewTab(Id) {
				window.open(`${process.env.VUE_APP_API_URI}LonHuvPDF/${Id}?isWebApp=true`, '_blank');
			},
		},

		computed: {
			...mapState(['isLoadingSenders', 'Senders', 'SelectedSender', 'SelectedAnstNr', 'AvailableAnstNrSelections', 'isLoadingSaldon', 'Saldon', 'isLoadingPayslips', 'Payslips']),

			saldoGroups() {
				const saldoGroups = [
					{image: 'Klocka.png', value: this.Saldon.Kompsaldo, formatOptions: {unit: 'tim', allowZero: true}, label: 'Kompsaldo'},
					{image: 'Sol.png', value: this.sumBetDgr, formatOptions: {unit: this.Saldon.SemUnit || 'dgr', allowZero: true}, label: 'Betald semester'},
					{image: 'Sol.png', value: this.sumSparDgr, formatOptions: {unit: this.Saldon.SemUnit || 'dgr', allowZero: true}, label: 'Sparad semester'},
					{image: 'Sol.png', value: this.sumObetDgr, formatOptions: {unit: this.Saldon.SemUnit || 'dgr', allowZero: true}, label: 'Obetald semester'},
					{image: 'Pengar.png', value: this.Saldon.AckBruttoLon, formatOptions: {unit: 'kr', allowZero: true}, label: 'Ackumulerad bruttolön'},
					{image: 'Pengar.png', value: this.Saldon.AckUtbetalt, formatOptions: {unit: 'kr', allowZero: true}, label: 'Ackumulerat utbetalt'},
				];

				return _.chunk(saldoGroups, this.$vuetify.breakpoint.xsOnly ? 1 : 2);
			},

			sumBetDgr() {
				const {SemBetDgr, SemBetUtb} = this.Saldon;

				return SemBetDgr - SemBetUtb;
			},

			sumSparDgr() {
				const {SemSparDgr, SemSparUtb} = this.Saldon;

				return SemSparDgr - SemSparUtb;
			},

			sumObetDgr() {
				const {SemObetDgr, SemObetUtb} = this.Saldon;

				return SemObetDgr - SemObetUtb;
			},

			computedFooterStyle() {
				return {
					top: 'auto',
					bottom: 0,
					'margin-left': `${this.$vuetify.application.left}px`,
					'background-color': '#f2f2f2',
					'border-top': '1px solid rgb(224, 224, 224)',
				};
			}
		},

		components: {
			AnimatedNumber,
		},
	}
</script>

<style>
	.v-carousel__controls {
		background: none;
	}
	.v-carousel__next, .v-carousel__prev {
		opacity: .7
	}

	.orange--text {
		color: #ea690b !important;
	}

	.v-btn--active.v-carousel__controls__item {
		color: #ea690b !important;
		background: none !important;
	}
	.v-carousel__controls__item.v-btn--active:before {
		background-color: transparent !important;
	}

	.flex-divider {
		border-right: 1px solid #d3c7d5;
	}
</style>

<template>
	<v-app>
		<v-fade-transition hide-on-leave>
			<v-app-bar app v-if="UserInfo" color="primary" dark flat clipped-left dense>
				<v-toolbar-title class="headline text-uppercase ml-0 pl-0">
					<v-app-bar-nav-icon @click="toggleSideMenu"></v-app-bar-nav-icon>
				</v-toolbar-title>

				<v-spacer></v-spacer>
				<v-img
						class="hidden-xs-only"
						:src="require('./assets/MlbLogoWhite.png')"
						:lazy-src="require('./assets/MlbLogoWhite.png')"
						contain
						:max-height="35"
						width="150"
						:style="{'margin-left': `${$vuetify.application.left}px`}"
						position="center center"
				/>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn text depressed>{{UserInfo ? UserInfo.Name : ''}}</v-btn>
				</v-toolbar-items>
			</v-app-bar>
		</v-fade-transition>

		<v-container fill-height v-if="loadingUser">
			<v-layout column justify-center>
				<v-flex xs12 sm8 md6 lg4 class="text-center">
					<v-progress-circular color="accent" indeterminate :size="100"></v-progress-circular>
				</v-flex>
			</v-layout>
		</v-container>

		<v-fade-transition hide-on-leave>
			<v-main v-if="!loadingUser && !UserInfo && !['Terms', 'Questions'].includes($route.name)">
				<LoginComponent @Authenticated="loadUser"/>
			</v-main>
		</v-fade-transition>

		<v-main v-if="(!loadingUser && UserInfo) || ['Terms', 'Questions'].includes($route.name)">
			<SideMenu ref="SideMenu" :userInfo="UserInfo"/>

			<v-fade-transition hide-on-leave>
				<router-view></router-view>
			</v-fade-transition>
		</v-main>
	</v-app>
</template>

<script>
	import LoginComponent from './components/LoginComponent';
	import SideMenu from './components/SideMenu';

	import HttpRequester from './lib/HttpRequester';

	export default {
		name: 'App',

		components: {
			LoginComponent,
			SideMenu,
		},

		async mounted() {
			this.loadUser();
		},

		methods: {
			toggleSideMenu() {
				this.$refs.SideMenu.toggle();
			},

			async loadUser() {
				try {
					this.loadingUser = true;

					const {data: Me} = await HttpRequester.get('/me');

					this.UserInfo = Me;

					this.$router.push({
						name: 'Payslips',
					})

				} catch (error) {
					this.UserInfo = null;
				} finally {
					this.loadingUser = false;
				}
			},
		},

		data() {
			return {
				loadingUser:    true,
				UserInfo:       null,
			}
		}
	}
</script>


<style>
	html {
		background: #fafafa;
	}
</style>

/**
 * Created by simon on 2019-09-03.
 */

'use strict';

import Router from '../router/index';
import HttpRequester from './HttpRequester';

HttpRequester.interceptors.response.use(
	(response) => response,
	(error) => {
		if(error.response && error.response.data && error.response.data.DVCode === 40102) {
			Router.push({name: 'Logout'});
		}

		return Promise.reject(error);
	});

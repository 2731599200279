<template>
	<v-container fill-height>
		<v-layout row wrap align-center justify-center>
			<v-flex xs12 lg6 class="text-center">
				<v-progress-circular indeterminate :size="80" color="primary"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
  import HttpRequester from '../lib/HttpRequester';

  export default {
    name: 'Logout',

    mounted() {
      this.logout();
    },

    methods: {
      async logout() {
        try {
          await HttpRequester.post('/logout');
        } catch(error) {

        } finally {
          this.$router.push({path: '/'});
          this.$router.go();
        }
      },
    },
  }
</script>

<style>

</style>

/**
 * Created by simon on 2019-01-21.
 */

'use strict';

import axios from 'axios';

export default axios.create({
	baseURL: process.env.VUE_APP_API_URI,
	headers: {
		'DV-API-REQUEST': true,
	},
	withCredentials: process.env.VUE_APP_UTV === 'true',
});

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const themeMarkup = {
  primary: '#7c7c7f',
  secondary: '#626c79',
  accent: '#ea690b',
  error: '#f44336',
  warning: '#ffc107',
  info: '#cddc39',
  success: '#4caf50',
};

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme:  {
    dark: false,
    themes: {
      light: themeMarkup,
      dark: themeMarkup,
    },
  }
});

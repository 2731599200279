<template>
	<v-container fluid grid-list-md>
		<v-layout row wrap justify-center>
			<template v-for="(item, index) in QA">
				<v-flex xs12 md10 lg8 :key="index">
					<v-card>
						<v-card-title>
							<h3 class="questions-headline title">{{item.question}}</h3>
						</v-card-title>
						<v-card-text>
							<p>{{item.answer}}</p>
						</v-card-text>
					</v-card>
				</v-flex>
			</template>
		</v-layout>
	</v-container>
</template>

<script>
	export default {
		data() {
			return {
				QA: [
					{
						question: 'Jag tycker inte att mitt lönebesked är korrekt. Vad gör jag?',
						answer: 'Prata med din löneadministratör. Precis som med ett lönebesked på papper är det din löneadministratör som bestämmer hur månadens lön ska beräknas och vilka lönehändelser som ska vara med.',
					},
					{
						question: 'Jag saknar ett lönebesked för en månad. Hur gör jag?',
						answer: 'Lönebeskeden skickas från företagets löneprogram. Normalt är första lönebesked den månad företaget börjar att skicka till appen. Totalt utbetald lön och saldon är dock alltid till och med senaste lönebesked för året. Prata med din löneadministratör och be att hen skickar saknade lönebesked.',
					},
					{
						question: 'Jag har tekniska problem med appen. Vart vänder jag mig?',
						answer: 'Med tekniska problem menar vi ifall appen kraschar, inte visar notifikationer, inte vill visa lönebeskeden, inte loggar ut eller in när du tänkt och så vidare. Här är du välkommen att maila till appar@datavara.se. Beskriv problemet så noga du kan, ange vilken telefon/platta du använder och vilken OS-version enheten har. Vi besvarar ditt mail så fort vi kan under kontorstid.',
					},
				],
			};
		}
	}
</script>

<style>
	.questions-headline {
		color: #555;
		width: 100%;
	}
</style>

<template>
	<span>{{ formattedValue }}</span>
</template>

<script>
	import TWEEN from '@tweenjs/tween.js';

	export default {
		name: "AnimatedNumber",

		data: () => ({
			formattedValue: '',
		}),

		props: {
			value: {
				type: Number,
				required: true
			},

			duration: {
				type: Number,
				default: 300
			},

			formatOptions: {
				type: Object,
				default: () => ({}),
			},
		},

		watch: {
			value(newVal, oldVal) {
				this.animate(oldVal, newVal)
			}
		},

		mounted() {
			this.animate(0, this.value)
		},

		methods: {
			// This is our main logic block. It handles tweening from a start value to an end value.
			animate(start, end) {
				let frameHandler;

				// Handles updating the tween on each frame.
				const animate = function (currentTime) {
					TWEEN.update(currentTime);
					frameHandler = requestAnimationFrame(animate)
				};

				const object = {value: start };
				const tweenInstance = new TWEEN.Tween(object);

				tweenInstance
					.to({ value: end }, this.duration)
					.onUpdate(() => {
						this.formattedValue = this.$formatDecimal(object.value, this.formatOptions);
					})
					.onComplete(() => {
						cancelAnimationFrame(frameHandler)
					})
					.start();

				frameHandler = requestAnimationFrame(animate)
			}
		}
	}
</script>

<style scoped>

</style>
<template>
	<v-container fluid grid-list-md>
		<v-layout row wrap justify-center>
			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title"></h3>
					</v-card-title>
					<v-card-text>
						<p>
							Denna integritetspolicy beskriver hur vi på DataVara AB, (556453-3817), behandlar dina personuppgifter. Denna Integritetspolicy riktar sig till dig som tar del av våra produkter, besöker vår hemsida eller funderar på att ladda ner appar från AppStore eller Google Play.
							Vi är helt transparenta med vilka uppgifter om dig som behandlas och för vilka ändamål. Därför ber vi dig att noga läsa igenom denna integritetspolicy, som beskriver vår behandling av dina uppgifter. Om du har några frågor angående behandlingen av dina personuppgifter ska du höra av dig till din arbetsgivare.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Produkter till försäljning</h3>
					</v-card-title>
					<v-card-text>
						<p>
							DataVara AB tillhandahåller ett antal olika applikationer (”appar”) enligt följande:
						</p>
						<p>
							<b>Crona Lön, Resa, Tid och Dokument:</b> Dessa är program har en lokal databas, den information som skickas till oss är endast licensinformation, aldrig några personuppgifter.
						</p>
						<p>
							<b>Crona Lönebesked:</b>  En app som är kopplad till Crona Lön via en server som vi administrerar och då hanterar personuppgifter. Appen presenterar personuppgifter som lönebesked och saldouppgifter för den anställde (BankId krävs). För att kunna tillhandahålla denna tjänst och tillhörande kundservice, behandlas personuppgifter på en server vi administrerar.
						</p>
						<p>
							<b>Crona Portal:</b> En internetportal som är kopplad via en server som vi administrerar och hanterar personuppgifter. Appen rapporterar in och presenterar personuppgifter i lönesammanhang. För att kunna tillhandahålla denna tjänst och tillhörande kundservice, behandlas självklart personuppgifter.
						</p>
						<p>
							<b>Crona Bemanna:</b> En funktion inom Crona Portal med likartad säkerhet och hantering av personuppgifter.
						</p>
						<p>
							<b>Crona API:</b> En funktion för externa program och appar att kommunicera med Crona Portal.
						</p>
						<p>
							<b>Crona Reseräkning iPhone:</b> Vi har ingen tillgång till personuppgifter, dessa sparas endast i mobilen. Vid överföring av reseräkningsdata till ett löneprogram sker detta via mail eller Dropbox. Appen hanterar inte känslig personinformation.
						</p>
						<p>
							<b>Crona Entré 50:</b> Vi har ingen tillgång till personuppgifter, dessa sparas endast i kundens iPad. Vid överföring av tidsuppgifter till ett löneprogram sker detta via mail eller Dropbox.
						</p>
						<p>
							<b>Lönekalkylatorn 20##:</b> Inga personuppgifter.
						</p>
						<p>
							Ovanstående produkter är huvudsakligen verktyg för din arbetsgivare för att fullgöra sina rättsliga förpliktelser gentemot dig som anställd och mot olika myndigheter.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Interna arbetsredskap</h3>
					</v-card-title>
					<v-card-text>
						<p>
							<b>Hemsidan, www.datavara.se:</b>  DataVara AB samlar in upplysningar genom formulär på våra hemsidor. Det är alltid frivilligt att uppge denna information. När du uppger den informationen framgår det vilka tjänster och produkter du/ni är intresserade av eller ingått avtal kring. Om du väljer att inte uppge personuppgifter, kan vi vara förhindrade från att ge dig tillgång till vissa produkter eller tjänster.
						</p>
						<p>
							Vi på DataVara AB använder inte cookies på vår hemsida (Cookies som är små filer som skickas till och lagras på din enhet när du besöker en webbplats).
						</p>
						<p>
							Vi behandlar inte dina personuppgifter på annat sätt än att informationen lagras på servrar som vi administrerar direkt eller via ett underbiträde.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Integritetspolicy</h3>
					</v-card-title>
					<v-card-text>
						<p>
							Personuppgiftsansvarig är din arbetsgivare som tillhandahåller tjänsten till dig som anställd, vi kan vara personuppgiftsbiträde och har då ansvar gentemot den personuppgiftsansvarig. Som personuppgiftsbiträde behandlar vi alltid personuppgifter i enlighet med gällande lag, inklusive EU:s dataskyddsförordning. DataVara AB arbetar aktivt med att skydda dina personuppgifter genom att vidta tekniska och organisatoriska åtgärder. Personuppgiftsbiträdesavtal är tecknat med din arbetsgivare beroende på produkt.
						</p>
						<p>
							Med begreppet behandling avses endast lagring av personuppgifter samt bearbetning för användning i arbetsgivarens personalsystem. Vi som personuppgiftsbiträde bearbetar inte personuppgifter på annat sätt än att på din arbetsgivares uppdrag spara och säkerhetskopiera dessa personuppgifter.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Vilka personuppgifter behandlas?</h3>
					</v-card-title>
					<v-card-text>
						<p>
							Vilka personuppgifter behandlas, varför, på vilken laglig grund och hur länge ske behandlingen?
							I våra produkter och på din arbetsgivares uppdrag hämtas personuppgifter direkt från dig, men också direkt från din arbetsgivare eller ett företag du representerar. I produkterna behandlas uppgifter som ligger till underlag för lön, ersättningar, närvaro, frånvaro, schema, etc. Ändamålet är arbetsgivaren legitima skäl för att kunna utföra sin personaladministration. Något samtycke från dig som arbetstagare behöves normalt inte enligt dataskyddsförordningen.
						</p>
						<p>
							Uppgifterna om dig hanteras endast av din arbetsgivare. Vi kan också komma att lämna ut dina personuppgifter till myndigheter som kräver att vi lämnar ut sådana personuppgifter, detta efter godkännande av den personuppgiftsansvarige, dvs. din arbetsgivare.
						</p>
						<p>
							Utöver vad som nämns ovan kan vi komma att behandla dina personuppgifter för att kunna fastställa, göra gällande eller försvara rättsliga anspråk, för att kunna ta tillvara våra eller tredje mans rättigheter. Den lagliga grunden för sådan behandling är en intresseavvägning där det berättigade intresset är vårt eller tredje mans intresse av att kunna tillvarata sina rättigheter.
						</p>
						<p>
							Vi kan komma att ta emot e-post och annan korrespondens som innehåller uppgifter om dig som tredje part och vi kan därför även komma att behandla sådana personuppgifter om dig.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Dina rättigheter</h3>
					</v-card-title>
					<v-card-text>
						<p>
							När dina personuppgifter behandlas har du vissa rättigheter enligt lag. Kontakta din arbetsgivare för mer information. Om dina personuppgifter är felaktiga eller ofullständiga har du rätt att begära att de rättas eller kompletterar sådana personuppgifter.
						</p>
						<p>
							Du har rätt att begära radering av dina personuppgifter under vissa omständigheter (vilka anges i EU:s dataskyddsförordning).
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Hur lagras informationen?</h3>
					</v-card-title>
					<v-card-text>
						<p>
							Information som inhämtats genom formulär på www.datavara.se lagras i vårt CRM-system.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Hur länge lagras uppgifter?</h3>
					</v-card-title>
					<v-card-text>
						<p>
							DataVara AB lagrar inte personuppgifter längre tid än det som är nödvändigt, men så länge som det be-hövs för att kunna hantera kundförhållandet eller andra tjänster. Vi har rutiner för radering av data relaterat till kontakter som inte längre är aktiva hos oss. I några fall måste vi dock spara data längre tid på grund av olika lagar. Vi raderar också information på direkt begäran av den registrerade. Detta görs genom att kontakta vår kundtjänst.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Utlämning av information till tredje part?</h3>
					</v-card-title>
					<v-card-text>
						<p>
							Personuppgifter delas inte ut till tredje part, men DataVara AB kan använda underleverantörer för att administrera hela eller delar av webbplatsen. Sådana underleverantörer kan behandla personuppgifter å våra vägnar. I sådana fall kan uppgifter bara användas för angett ändamål och DataVara AB har ingått biträdesavtal med alla företag som information delas med för att säkra god datasäkerhet.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title>
						<h3 class="terms-headline title">Informationssäkerhet</h3>
					</v-card-title>
					<v-card-text>
						<p>
							DataVara AB jobbar kontinuerligt med informationssäkerhet och för att säkra gott skydd för våra användares uppgifter. Vi har implementerat både tekniska och organisatoriska funktioner för att säkerställa säkerheten så gott som möjligt genom bland annat anonymisering, tillgångskontroll och raderingsrutiner.
						</p>
					</v-card-text>
				</v-card>
			</v-flex>

		</v-layout>
	</v-container>
</template>

<script>
	export default {}
</script>

<style>
	.terms-headline {
		color: #555;
		width: 100%;
	}

	.terms-subheading {
		font-style: italic;
	}
</style>

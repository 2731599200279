import Vue from 'vue'
import vuetify from './plugins/vuetify'
import store from './store/index';
import App from './App.vue'

import AutoNumeric from 'autonumeric';

import router from './router';

Vue.config.productionTip = false;

const autoNumericOptions = {
  digitGroupSeparator: ' ',
  decimalCharacter: ',',
  decimalCharacterAlternative: '.',
  decimalPlaces: 2,
  negativePositiveSignPlacement: 'l',
};

Vue.prototype.$formatDecimal = (value, options = {}) => {
  if('string' === typeof value) {
    const isNegative = value[0] === '-';

    value = value.replace(/,/g, '.').replace(/ /g, '').replace(/[^0-9\.]+/, '');
    if(!value.match(/[0-9]/)) {
      value = 0;
    } else if(isNegative) {
      value = -value;
    }
  }

  let formattedValue = '0,00';

  try {
    let autoNumericFormat = AutoNumeric.format(value, {...autoNumericOptions, ...options});
    formattedValue = (autoNumericFormat === null || autoNumericFormat === undefined) ? formattedValue : autoNumericFormat;
  } catch(error) {
    formattedValue = '0,00';
  }

  if(formattedValue === '0,00' && options.allowZero !== true) {
    return '';
  }

  return options.unit ? [formattedValue, options.unit].join(' ') : formattedValue;
};

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify,
}).$mount('#app')

import './lib/HttpErrorHandler';

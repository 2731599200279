<template>
	<v-container fill-height class="pa-5">
		<v-layout
						text-center
						wrap
						justify-center
						align-center
		>

			<v-flex xs12 sm8 md6 lg4>
				<v-img
						:src="require('../assets/MlbLogo.png')"
						:lazy-src="require('../assets/MlbLogo.png')"
						contain
						:class="{
										'ml-5': $vuetify.breakpoint.smAndUp,
										'ml-4': $vuetify.breakpoint.xsOnly,
										'mb-5': true,
										}"
						style="margin-top: -30%;"
						:max-height="175"
				>
				</v-img>

				<v-layout column v-if="isLoading">
					<v-flex xs12>
						<v-card>
							<v-card-text v-if="loginType === 'BANKID'">
								<img v-if="QRCodeDataURL" :src="QRCodeDataURL" style="margin: 0 auto; border: 1px solid gray; display: block;"/>
								<div class="mt-3 mb-2">
									{{bankIDStatusMessage}}
								</div>
								<v-progress-linear indeterminate :size="75" color="primary"></v-progress-linear>
							</v-card-text>

							<v-card-text v-if="loginType === 'MOBILE'">
								<template v-if="!showPinCodeForm">
									<v-text-field autofocus placeholder="Verifieringskod" v-model="verificationCode" @keyup.enter="onConfirmVerificationCode" :disabled="isConfirmingVerificationCode"/>
									<v-btn color="primary" style="width: 100%;" :disabled="!verificationCode || !verificationCode.trim()" @click="onConfirmVerificationCode" :loading="isConfirmingVerificationCode">Bekräfta</v-btn>
								</template>

								<template v-if="showPinCodeForm && !hasPinCode">
									<blockquote class="text-left blockquote">För extra säkerhet behöver du ange en PIN-Kod som kommer användas vid vidare inloggning.</blockquote>
									<v-text-field autofocus placeholder="PIN-Kod" v-model="pinCode" mask="#########"></v-text-field>
									<v-btn color="primary" style="width: 100%;" :disabled="!pinCode || !pinCode.trim()" @click="onSetPinCode" :loading="isPinCodeLoading">Bekräfta</v-btn>
								</template>

								<template v-if="showPinCodeForm && hasPinCode">
									<blockquote class="text-left blockquote">Har du glömt av din PIN-Kod kontaktar du din arbetsgivare.</blockquote>
									<v-text-field autofocus placeholder="PIN-Kod" v-model="pinCode" mask="#########"></v-text-field>
									<v-btn color="primary" style="width: 100%;" :disabled="!pinCode || !pinCode.trim()" @click="loginWithPinCode" :loading="isPinCodeLoading">Bekräfta</v-btn>
								</template>
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex xs12 class="pt-2">
					</v-flex>
					<v-flex xs12 class="mt-3">
						<v-btn class="pa-0 ma-0" color="primary" style="width: 100%;" @click="onCancel">Avbryt inloggning</v-btn>
					</v-flex>
				</v-layout>

				<v-form ref="form" v-if="!isLoading">
					<v-layout row wrap>
						<v-flex xs12 v-if="loginType !== 'BANKID'">
							<v-text-field
											solo
											v-model="Input"
											autofocus
											:label="'Telefonnummer (inklusive landsnummer)'"
											@keyup.enter="onLogin"
											append-icon="mdi-cellphone-iphone"
											:disabled="isSendingAuthRequest"
							>
								<template slot="append">
									<v-img v-if="loginType === 'BANKID'" width="40" height="40" style="vertical-align: middle;"
										:src="require('../assets/bankid_icon.png')"
										:lazy-src="require('../assets/bankid_icon.png')"></v-img>
									<v-icon v-if="loginType === 'PHONE'">phone</v-icon>
								</template>
							</v-text-field>

						</v-flex>
						<v-flex xs12 :class="{'mt-5': loginType === 'BANKID', 'pt-5': loginType === 'BANKID'}">
							<v-btn light :loading="isSendingAuthRequest" :disabled="loginType === 'BANKID' ? false : (!Input || !Input.trim())" class="pa-0 ma-0" color="primary" @click="onLogin" large style="width: 100%;">Logga in</v-btn>
						</v-flex>
						<v-flex xs12 sm6>
							<v-switch
									v-if="loginType !== 'BANKID'"
											color="primary"
											:label="'Kom ihåg mitt telefonnummer'"
											v-model="rememberMe"
							></v-switch>
						</v-flex>
						<v-flex xs12 sm6>
							<v-btn text color="secondary" class="mt-3" @click="toggleLoginType">{{loginType === 'BANKID' ? 'Logga in med telefonnummer' : 'Logga in med Mobilt BankID'}}</v-btn>
						</v-flex>
					</v-layout>
				</v-form>
			</v-flex>

			<v-snackbar
							v-if="errorMessage"
							:value="true"
							color="error"
							:timeout="10000"
			>
				{{ errorMessage }}
			</v-snackbar>
		</v-layout>
	</v-container>
</template>

<script>
	import HttpRequester from '../lib/HttpRequester';

	import {get} from 'lodash'
	import isMobile from 'is-mobile';

	const localStorageKeyInput = 'DV_MITT_LONEBESKED';
	const localStorageKeyType = 'DV_MITT_LONEBESKED_TYPE';

	export default {
		data: () => ({
			Input:          '',
			rememberMe:      localStorage && !!localStorage.getItem(localStorageKeyInput),
			isLoading:       false,
			isSendingAuthRequest: false,
			isConfirmingVerificationCode: false,
			errorMessage: '',
			bankIDStatusMessage: '',
			orderRef: null,
			verificationCode: '',
			showPinCodeForm: false,
			pinCode: '',
			hasPinCode: false,
			isPinCodeLoading: false,
			loginType: localStorage && localStorage.getItem(localStorageKeyType) || 'BANKID',
			QRCodeDataURL: null,
		}),

		mounted() {
			if(localStorage) {
				try {
					const type = localStorage.getItem(localStorageKeyType) || 'BANKID';
					const input = localStorage.getItem(localStorageKeyInput);

					if(type === 'MOBILE') {
						this.Input = input;
					}
				} catch (error) {
				}
			}
		},

		methods: {
			async onSetPinCode() {
				try {
					this.isPinCodeLoading = true;

					await HttpRequester.post('/SMSPinCode', {
						SMSPinCode: [{
							PhoneNumber: this.Input,
							VerificationCode: this.verificationCode,
							PinCode: this.pinCode,
						}],
					});

					this.loginWithPinCode();
				} catch(error) {

				} finally {
					this.isPinCodeLoading = false;
				}
			},

			async loginWithPinCode() {
				try {
					this.errorMessage = '';
					await HttpRequester.get(`/SMSAccessToken/${encodeURIComponent(this.Input)}/${encodeURIComponent(this.verificationCode)}/${encodeURIComponent(this.pinCode)}`);

					this.$emit('Authenticated', {});
				} catch(error) {
					this.errorMessage = get(error, 'response.data.Message', 'Kunde inte logga in.');
				}
			},

			toggleLoginType() {
				this.loginType = this.loginType === 'BANKID' ? 'MOBILE' : 'BANKID';
				this.Input = '';
			},

			async onConfirmVerificationCode() {
				try {
					this.errorMessage = '';
					this.isConfirmingVerificationCode = true;

					const {data: {SMSVerification: [{IsValid, HasPinCode}]}} = await HttpRequester.get(`/SMSVerification/${encodeURIComponent(this.Input)}/${encodeURIComponent(this.verificationCode)}`);

					if(IsValid) {
						this.hasPinCode = HasPinCode;
						this.showPinCodeForm = true;
					} else {
						this.errorMessage = 'Felaktig eller utgången verifieringskod.';
					}

				} catch(error) {
					this.errorMessage = 'Felaktig eller utgången verifieringskod.';
				} finally {
					this.isConfirmingVerificationCode = false;
				}
			},

			async onLogin() {
				this.errorMessage = '';
				this.bankIDStatusMessage = '';

				this.isSendingAuthRequest = true;

				try {
					let autoStartToken = null;

					if(this.loginType === 'BANKID') {
						const {data: {BankIDAuthentication: [{OrderRef, AutoStartToken}]}} = await HttpRequester.post('/BankIDAuthentication');

						this.orderRef = OrderRef;
						autoStartToken = AutoStartToken;
					} else if(this.loginType === 'MOBILE') {
						await HttpRequester.post('/SMSVerificationCode', {
							SMSVerificationCode: [{
								PhoneNumber: this.Input,
							}],
						});

						this.isLoading = true;
					} else {
						throw new TypeError(`Invalid loginType: ${this.loginType}`);
					}

					if(this.rememberMe) {
						localStorage.setItem(localStorageKeyInput, this.loginType === 'BANKID' ? '' : this.Input);
						localStorage.setItem(localStorageKeyType, this.loginType);
					}

					if(this.loginType === 'BANKID') {
						setTimeout(() => {
							this.collect(autoStartToken);
						}, 1700);
					}
				} catch(error) {
					//Åtgärden avbruten, simply ignore and issue another request
					if(error.response && error.response.data && error.response.data.DVCode === 40099) {
						await this.onLogin();
					} else if(error.response && error.response.data && error.response.data.DVCode === 49999) {
						this.errorMessage = error.response.data.Message;
						this.isSendingAuthRequest = false;
					} else {
						this.errorMessage = 'Inloggning misslyckades. Vänligen försök igen.';
						this.isSendingAuthRequest = false;
					}
				}
			},

			async onCancel() {
				this.isSendingAuthRequest = false;
				this.isLoading = false;
				this.verificationCode = '';
				this.pinCode = ''
				this.hasPinCode = false;
				this.showPinCodeForm = false;
				this.isConfirmingVerificationCode = false;
				this.errorMessage = '';
				this.bankIDStatusMessage = '';

				const orderRef = this.orderRef;

				//Ensures any collect current waiting for 'timeout' gets cancelled/exits
				this.orderRef = null;

				if(orderRef) {
					try {
						await HttpRequester.delete(`/BankIDCancel/${orderRef}`);
					} catch(error) {

					}
				}
			},

			async collect(autoStartToken = null) {
				const orderRef = this.orderRef;

				if(!orderRef) {
					return;
				}

				if(autoStartToken && isMobile()) {
					const params = [
						`autostarttoken=${autoStartToken}`,
						'redirect=null',
					];

					window.location = `bankid:///?${params.join('&')}`;
				}

				try {
					const response = await HttpRequester.get(`/BankIDStatus/${orderRef}`);

					const {status: httpStatus} = response;

					if(httpStatus === 204) {
						this.onCancel();
						this.errorMessage = 'Du har inte mottagit något lönebesked i denna applikation.';
						return;
					}

					const {data: {BankIDStatus: [Status]}} = response;

					if(Status.PendingStart && Status.QRCodeDataURL) {
						this.QRCodeDataURL = Status.QRCodeDataURL;
						this.bankIDStatusMessage    = 'Skanna QR-koden i BankID-appen';
					} else {
						this.QRCodeDataURL = '';
						this.bankIDStatusMessage = Status.Message;
					}

					this.isLoading = true;
					this.isSendingAuthRequest = false;

					if(Status.Completed) {
						this.$emit('Authenticated', Status);
					} else if(!Status.Cancelled) {
						setTimeout(() => {
							this.collect();
						}, 1700);
					} else if(Status.Cancelled) {
						this.onCancel();
						this.errorMessage = 'BankID inloggning avbröts.';
					}
				} catch(error) {
					this.errorMessage = error.Message;
				}

			}
		},
	}
</script>

<style>

</style>

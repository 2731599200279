/**
 * Created by simon on 2019-06-25.
 */

'use strict';

import Vue from 'vue'

Vue.use(Vuex);

import Vuex from 'vuex';
import Router from '../router/index';

import HttpRequester from '../lib/HttpRequester';

const setSelectedSender = async (context, senderId) => {
	if(senderId === context.state.SelectedSender) {
		return;
	}

	const senderItem = context.state.Senders.find((item) => item.Id === senderId);

	if(senderItem) {
		const {AvailableSelections} = senderItem;

		context.state.AvailableAnstNrSelections = AvailableSelections;
		context.state.SelectedAnstNr = AvailableSelections[0].AnstNr;
		context.state.SelectedSender = senderId;
		context.state.lastSaldonLoad = null;
		context.state.lastPayslipLoad = null;

		Router.push({
			name: 'Payslips',
			params: {
				SenderId: senderId,
			},
		});
	}
};

export default new Vuex.Store({
	state: {
		Senders: [],
		SelectedSender: null,
		AvailableAnstNrSelections: [],
		SelectedAnstNr: null,
		isLoadingSenders: false,
		lastSendersLoad: null,

		Saldon: null,
		isLoadingSaldon: false,
		lastSaldonLoad: null,

		Payslips: [],
		isLoadingPayslips: false,
		lastPayslipLoad: null,
	},

	actions: {
		setSelectedSender,

		setSelectedAnstNr(context, value) {
			context.state.SelectedAnstNr = value;
		},

		async loadSenders(context, {forced = false} = {}) {
			try {
				if(forced === true || !context.state.lastSendersLoad || new Date() - context.state.lastSendersLoad > 30000) {
					context.state.isLoadingSenders = true;

					const {data: {Senders}} = await HttpRequester.get('/Senders');

					context.state.Senders        = Senders;

					await setSelectedSender(context, Senders[0].Id);

					context.state.lastSendersLoad = new Date();
				}
			} catch(error) {

			} finally {
				context.state.isLoadingSenders = false;
			}
		},

		async loadSaldon(context, {force = false} = {}) {
			try {
				if(context.state.SelectedSender && (!context.state.isLoadingSaldon || force === true || !context.state.lastSaldonLoad || new Date() - context.state.lastSaldonLoad > 30000)) {
					context.state.isLoadingSaldon = true;

					if(!context.state.Saldon || (context.state.Saldon.SenderId !== context.state.SelectedSender || context.state.Saldon.AnstNr !== context.state.SelectedAnstNr)) {

						const {data: {PerSaldon: [PerSaldon]}} = await HttpRequester.get(`/PerSaldon/${context.state.SelectedSender}/${context.state.SelectedAnstNr}`);

						context.state.Saldon = Object.assign(PerSaldon, {
							AnstNr: context.state.SelectedAnstNr,
							SenderId: context.state.SelectedSender,
						});
					}

					context.state.isLoadingSaldon = new Date();
				}
			} catch(error) {

			} finally {
				context.state.isLoadingSaldon = false;
			}
		},

		async loadPayslips(context, {force = false} = {}) {
			try {
				if(context.state.SelectedSender && (force === true || !context.state.lastPayslipLoad || new Date() - context.state.lastPayslipLoad > 30000)) {
					context.state.isLoadingPayslips = true;

					const {data: {LonHuvLista}} = await HttpRequester.get(`/LonHuvLista/${context.state.SelectedSender}/${context.state.SelectedAnstNr}`);

					context.state.Payslips = LonHuvLista;

					context.state.lastPayslipLoad = new Date();
				}
			} catch(error) {

			} finally {
				context.state.isLoadingPayslips = false;
			}
		}
	},
})

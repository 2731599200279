<template>
	<v-navigation-drawer
					v-if="userInfo && userInfo.Name"
					v-model="visible"
					app
					width="200"
					clipped
					class="pa-0"
	>
		<v-layout column fill-height>

			<v-list class="pt-0" dense>
				<v-divider></v-divider>

				<v-list-item
								:to="`/payslips/${SelectedSender}`"
								active-class="activerouterlink"
				>
					<v-list-item-avatar tile>
						<v-img
										:src="require('../assets/Lonebesked.png')"
										:lazy-src="require('../assets/Lonebesked.png')"
										class="my-3"
										contain
										width="35"
										height="35"
						></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>Lönebesked</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
								to="/questions"
								active-class="activerouterlink"
				>
					<v-list-item-avatar tile>
						<v-img
										:src="require('../assets/Chat.png')"
										:lazy-src="require('../assets/Chat.png')"
										class="my-3"
										contain
										width="35"
										height="35"
						></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>Frågor och svar</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
								to="/terms"
								active-class="activerouterlink"
				>
					<v-list-item-avatar tile>
						<v-img
										:src="require('../assets/Shield.png')"
										:lazy-src="require('../assets/Shield.png')"
										class="my-3"
										contain
										width="35"
										height="35"
						></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>Integritetspolicy</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
								to="/settings"
								active-class="activerouterlink"
				>
					<v-list-item-avatar tile>
						<v-img
										:src="require('../assets/Settings.png')"
										:lazy-src="require('../assets/Settings.png')"
										class="my-3"
										contain
										width="35"
										height="35"
						></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>Inställningar</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-spacer></v-spacer>

			<v-list>
				<v-list-item @click="$router.push({name: 'Logout'})">
					<v-list-item-action>
						<v-icon>mdi-logout</v-icon>
					</v-list-item-action>
					<v-list-item-title>Logga ut</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-layout>
	</v-navigation-drawer>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		props: {
			userInfo: {
				required: true,
			}
		},

		data: () => ({
			visible: true,
		}),

		computed: {
			...mapState(['SelectedSender']),
		},

		methods: {
			toggle() {
				this.visible = !this.visible;
			},
		},
	}
</script>

<style>
	.activerouterlink {
		color: #ea690b !important;
	}
</style>

<template>
	<v-container fluid grid-list-md>
		<v-layout row wrap justify-center>
			<v-flex xs12 md10 lg8>
				<v-card>
					<v-card-title class="headline">Rensa information</v-card-title>
					<v-card-text>
						<p>
							Här har du möjlighet att rensa alla mottagna lönebesked och saldon som vi lagrar från olika avsändare.
						</p>

						<v-layout row wrap>
							<v-flex xs12 sm6 md4 v-if="isLoading || Senders.length > 0">
								<v-select :loading="isLoading" placeholder="Alla avsändare" clearable item-value="Id" item-text="Namn" :items="Senders" v-model="selectedSender"></v-select>
							</v-flex>

							<v-flex xs12></v-flex>

							<v-flex xs12 sm6 md4 v-if="!isLoading && !Senders.length">
								<v-alert :value="true" color="cyan">Du har inte mottagit lönebesked från någon.</v-alert>
							</v-flex>
						</v-layout>

					</v-card-text>
					<v-card-actions>
						<v-btn color="red" class="white--text" :disabled="!selectedSender" @click="dialog = true">Rensa</v-btn>
					</v-card-actions>
				</v-card>
			</v-flex>

			<v-dialog
							v-model="dialog"
							max-width="450"
			>
				<v-card>
					<v-card-title class="headline">Rensa information</v-card-title>

					<v-card-text>
						Är du säker på att du vill rensa <b>all</b> information från vald avsändare?
						Alla data går förlorad och kan inte återfås.
					</v-card-text>

					<v-card-actions>

						<v-btn
										color="primary"
										@click="dialog = false"
										:disabled="isDeleting"
						>
							Avbryt
						</v-btn>

						<v-spacer></v-spacer>

						<v-btn
										color="red"
										class="white--text"
										@click="onConfirm"
										:loading="isDeleting"
						>
							Ja, radera
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-snackbar
							v-if="errorMessage"
							:value="true"
							color="error"
							:timeout="10000"
			>
				{{ errorMessage }}
			</v-snackbar>
		</v-layout>
	</v-container>
</template>

<script>
	import {mapState, mapActions} from 'vuex';

	import HttpRequester from '../lib/HttpRequester';

	export default {
		name: "Settings",

		data: () => ({
			dialog: false,
			isDeleting: false,
			isLoading: false,
			selectedSender: '',
			errorMessage: '',
		}),

		methods: {
			...mapActions(['loadSenders']),

			async loadData() {
				try {
					this.isLoading = true;
					await this.loadSenders(true);
				} catch(error) {

				} finally {
					this.isLoading = false;
				}
			},

			async onConfirm() {
				try {
					this.errorMessage = '';

					this.isDeleting = true;

					await HttpRequester.delete(`/Sender/${encodeURIComponent(this.selectedSender)}`);

					this.dialog = false;

					this.loadData();

				} catch(error) {
					console.log(error.response || error);
					this.errorMessage = 'Kunde inte rensa informationen från vald avsändare.';
				} finally {
					this.isDeleting = false;
				}
			}
		},

		computed: {
			...mapState(['Senders']),
		},

		mounted() {
			this.errorMessage = '';
			this.loadData();
		},
	}
</script>

<style scoped>

</style>

/**
 * Created by simon on 2019-01-22.
 */

'use strict';

import Vue from 'vue'
import Router from 'vue-router'

import Payslips from '@/components/Payslips';
import Logout from '@/components/Logout';
import Terms from '@/components/Terms';
import Questions from '@/components/Questions';
import Settings from '@/components/Settings';

Vue.use(Router);

export default new Router({
	routes: [
		{
			path: '/questions',
			name: 'Questions',
			component: Questions,
		},
		{
			path: '/terms',
			name: 'Terms',
			component: Terms,
		},
		{
			path: '/logout',
			name: 'Logout',
			component: Logout,
		},
		{
			path:      '/payslips/:SenderId?',
			name:      'Payslips',
			component: Payslips,
		},
		{
			path: '/settings',
			name: 'Settings',
			component: Settings,
		},
	]
})
